// This is the register page that creates a new user account

import { useState, useEffect } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Checkbox,
  Alert,
} from "@mui/material"

import GenericContainer from "../ui/GenericContainer"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const Register = () => {
  const navigate = useNavigate()

  const [initialRender, setInitialRender] = useState(0)
  useEffect(() => {
    document.title = "Factored - Register"
    setInitialRender(1)
  }, [])

  const [passwordShow, setPasswordShow] = useState(false)

  const [regError, setRegError] = useState("")
  const [serverErrorMessage, setServerErrorMessage] = useState("")
  const [formMessage, setFormMessage] = useState("")
  // The below state defines the default form values at the start
  const [regFormData, setRegFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    acceptTermsConditions: false,
  })

  const [errors, setErrors] = useState({
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    acceptTermsConditions: "",
  })

  const onlyLettersCheck = (stringValue) => {
    // This function checks if the field filled only has letters
    return /^[a-zA-Z]+$/.test(stringValue)
  }

  let formHasNoErrors = false

  const newErrors = {}

  const formFieldValidation = (entryName, entryValue) => {
    // console.log("name", entryName, "value", entryValue)
    // console.log(errors)
    if (entryName === "title") {
      if (!entryValue) {
        return (newErrors["title"] = "Title is required")
      }
    } else if (entryName === "firstName") {
      if (!entryValue) {
        // console.log("first if ")
        // setErrors({ ...errors, firstName: "First name is required" })
        // console.log(errors)
        return (newErrors["firstName"] = "First name is required")
      }

      // else if (!onlyLettersCheck(entryValue)) {
      //   // console.log("second if ")
      //   // setErrors({
      //   //   ...errors,
      //   //   firstName: "First name must be valid (only letters)",
      //   // })
      //   return (newErrors["firstName"] =
      //     "First name must be valid (only letters)")
      // }
      else {
        // setErrors({
        //   ...errors,
        //   firstName: "",
        // })
        return (newErrors["firstName"] = "")
      }
    } else if (entryName === "lastName") {
      if (!entryValue) {
        // setErrors({ ...errors, lastName: "Last name is required" })
        return (newErrors["lastName"] = "Last name is required")
      }

      // else if (!onlyLettersCheck(entryValue)) {
      //   // setErrors({
      //   //   ...errors,
      //   //   lastName: "Last name must be valid (only letters)",
      //   // })
      //   return (newErrors["lastName"] =
      //     "Last name must be valid (only letters)")
      // }
      else {
        // setErrors({
        //   ...errors,
        //   lastName: "",
        // })
        return (newErrors["lastName"] = "")
      }
    } else if (entryName === "emailAddress") {
      if (entryValue === "") {
        // setErrors({
        //   ...errors,
        //   emailAddress: "Email address is required",
        // })
        return (newErrors["emailAddress"] = "Email address is required")
      } else if (!entryValue?.includes("@") || !entryValue?.includes(".")) {
        // setErrors({
        //   ...errors,
        //   emailAddress: "Please enter a valid email address",
        // })
        return (newErrors["emailAddress"] =
          "Please enter a valid email address")
      } else {
        // setErrors({
        //   ...errors,
        //   emailAddress: "",
        // })
        return (newErrors["emailAddress"] = "")
      }
    } else if (entryName === "password") {
      if (entryValue === "") {
        // setErrors({
        //   ...errors,
        //   password: "Password is required",
        // })
        return (newErrors["password"] = "Password is required")
      } else if (entryValue === entryValue.toLowerCase()) {
        // setErrors({
        //   ...errors,
        //   password: "Password needs to have at least one upper case letter",
        // })
        return (newErrors["password"] =
          "Password needs to have at least one upper case letter")
      } else if (!/\d/g.test(entryValue)) {
        // setErrors({
        //   ...errors,
        //   password: "Password needs to have at least one number",
        // })
        return (newErrors["password"] =
          "Password needs to have at least one number")
      } else if (entryValue.length < 8) {
        // setErrors({
        //   ...errors,
        //   password: "Password must be at least 8 characters long",
        // })
        return (newErrors["password"] =
          "Password must be at least 8 characters long")
      } else {
        // setErrors({
        //   ...errors,
        //   password: "",
        // })
        return (newErrors["password"] = "")
      }
    } else if (entryName === "acceptTermsConditions") {
      if (!entryValue) {
        return (newErrors["acceptTermsConditions"] =
          "Need to accept Terms & Conditions and Privacy Policy before creating an account")
      } else {
        return (newErrors["acceptTermsConditions"] = "")
      }
    }
    return (formHasNoErrors = true)
  }

  const API_URL = "api/user/create/"

  const makeAPIRequest = async () => {
    try {
      const response = await axios.post(API_URL, {
        email: regFormData.emailAddress,
        title: regFormData.title,
        first_name: regFormData.firstName,
        last_name: regFormData.lastName,
        password: regFormData.password,
      })
      // console.log(response)
      setRegError("")
      setServerErrorMessage("")
      setFormMessage(
        "Account created successfully!",
      )
      // The timeout navigates to the login page if account was created
      setTimeout(() => {
        navigate("/login")
      }, 3000)
    } catch (error) {
      // console.log(error.response.data)
      setServerErrorMessage(error.response.data)
      // setRegError(error.response.data)
    }
  }

  const handleRegisterFormSubmit = (e) => {
    e.preventDefault()
    setRegError("")
    formHasNoErrors = false
    Object.entries(regFormData).map((itemKey) => {
      // console.log(itemKey)
      formFieldValidation(`${itemKey[0]}`, itemKey[1])
    })
    setErrors(newErrors)
    if (formHasNoErrors) {
      makeAPIRequest()
    }
  }

  const handleChange = (e) => {
    if (e.target.name === "acceptTermsConditions") {
      setRegFormData({
        ...regFormData,
        acceptTermsConditions: !regFormData.acceptTermsConditions,
      })
    } else {
      formFieldValidation(e.target.name, e.target.value)
      setErrors(newErrors)
      setRegFormData({ ...regFormData, [e.target.name]: e.target.value })
    }
  }

  const displayServerErrors = () => {
    return Object.entries(serverErrorMessage).map((itemKey) => {
      let title = itemKey[0]
      if (itemKey[0].includes("_")) {
        title = itemKey[0].replace(/_/g, " ")
      }
      return (
        <Alert severity="error">
          {title.charAt(0).toUpperCase() + title.slice(1)} - {itemKey[1][0]}
        </Alert>
      )
    })
  }

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Register New Account
      </Typography>
      <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Title*</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={regFormData.title}
          label="title"
          name="title"
          onChange={handleChange}
          error={errors.title ? true : false}
          helperText={errors.title && `${errors.title}`}
        >
          <MenuItem value="Mrs">Mrs</MenuItem>
          <MenuItem value="Miss">Miss</MenuItem>
          <MenuItem value="Ms">Ms</MenuItem>
          <MenuItem value="Mr">Mr</MenuItem>
        </Select>
      </FormControl>
      <TextField
        error={errors.firstName ? true : false}
        helperText={errors.firstName && `${errors.firstName}`}
        id="outlined-basic"
        label="First Name"
        variant="outlined"
        required
        type="text"
        name="firstName"
        value={regFormData.firstName}
        onChange={handleChange}
      />
      <TextField
        id="outlined-basic"
        label="Last Name"
        variant="outlined"
        value={regFormData.lastName}
        onChange={handleChange}
        required
        type="text"
        name="lastName"
        error={errors.lastName ? true : false}
        helperText={errors.lastName && `${errors.lastName}`}
      />
      <TextField
        id="outlined-basic"
        label="Email Address"
        variant="outlined"
        value={regFormData.emailAddress}
        onChange={handleChange}
        required
        type="email"
        name="emailAddress"
        error={errors.emailAddress ? true : false}
        helperText={errors.emailAddress && `${errors.emailAddress}`}
      />
      <TextField
        id="outlined-password-input"
        required
        label="Password"
        name="password"
        onChange={handleChange}
        error={errors.password ? true : false}
        type={passwordShow ? "text" : "password"}
        autoComplete="current-password"
        helperText={
          errors.password
            ? `${errors.password}`
            : `Password needs to be at least 8 characters long, 
          contain an upper case letter and at least 1 number`
        }
        FormHelperTextProps={{
          style: {
            color: errors.password ? "#d32f2f" : "black",
            fontWeight: "500",
            marginLeft: errors.password ? "14px" : "2px",
          },
        }}
      />
      <Typography
        variant="p"
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
          marginLeft: "2px",
        }}
        onClick={() => setPasswordShow(!passwordShow)}
      >
        {passwordShow ? "hide password" : "show password"}
      </Typography>
      <Box>
        <Checkbox
          checked={regFormData.acceptTermsConditions}
          onChange={handleChange}
          name="acceptTermsConditions"
          sx={{
            margin: "0px",
            padding: "0px",
            "& .MuiSvgIcon-root": { fontSize: 24 },
          }}
        />
        <label>
          I accept Factored's{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.factored.co/legal"
          >
            Terms & Conditions
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.factored.co/legal"
          >
            Privacy Policy
          </a>
        </label>
      </Box>
      {errors.acceptTermsConditions && (
        <Alert severity="error">{errors.acceptTermsConditions}</Alert>
      )}
      {serverErrorMessage && displayServerErrors()}
      {regError ? <Alert severity="error">{regError}</Alert> : ""}
      {formMessage ? <Alert severity="success">{formMessage}</Alert> : ""}
      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={handleRegisterFormSubmit}
      >
        <Typography variant="h6">Create Account</Typography>
      </Button>
    </GenericContainer>
  )
}

export default Register
